import React from 'react';
import T from 'i18n-react';

import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import texts from '../texts.json';

import SignupBar from './SignupBar/SignupBar';
import HowItWorks from './HowItWorks';
import MakesCarousel from './MakesCarousel';
import Masthead from './Masthead';
import NewTestimonials from './NewTestimonials';
import Testimonials from './Testimonials';

T.setTexts(texts);

const HomePage: React.FC = () => {
	const isNewLandingPageEnabled: boolean = useFeatureToggle(FEATURES.newLandingPage);

	return (
		<>
			<Masthead />

			<MakesCarousel />

			{isNewLandingPageEnabled && <NewTestimonials />}

			<HowItWorks />

			{!isNewLandingPageEnabled && <Testimonials />}

			<SignupBar />
		</>
	);
};

export default HomePage;
